.App {
  text-align: center;
  background-color: #3C465A;
  height: 100%;
  position: relative;
}

.Rawabi-Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Rawabi-VideoBG {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  z-index: 1;

  width: 100%;
  height: 100%;
}
.Rawabi-Side {
  position: relative;
  overflow: hidden;
  flex: 1;
  z-index: 2;
  height: 100vh;
}
.Rawabi-Side:before {
  position: absolute;
  /* content: ''; */
  top: 0;
  left: 0;
  height: 200px;
  width: 100%;
  background-image: linear-gradient(to bottom, #434D63 20%, rgba(60, 70, 90, 0) );
  z-index: 2;
}

.Rawabi-Side:after {
  position: absolute;
  /* content: ''; */
  bottom: 0;
  left: 0;
  height: 200px;
  width: 100%;
  background-image: linear-gradient(to top, #434D63 20%, rgba(60, 70, 90, 0) );
  z-index: 2;

}
.Rawabi-Graphic {
  position: absolute;
  max-width: 100%;
}
.Graphic-Left {
  left:0;
  top: 0;
}

.Rawabi-Center {
 justify-content: center;
  height: 100vh;
  overflow: hidden;
  flex: 1;
  min-width: 34%;
  z-index: 2
}
